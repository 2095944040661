// CKEDITOR
// ⚠️ NOTE: We don't use @ckeditor/ckeditor5-build-classic any more!
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

import { colorsWithLabel } from '@/services/utils/utils.service'
import { eventEmit } from '@/services/utils/utils.service'

// Plugins
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import FontSizePlugin from '@ckeditor/ckeditor5-font/src/fontsize'
import FontFamilyPlugin from '@ckeditor/ckeditor5-font/src/fontfamily'
import UnderlinedPlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import AlignmentPlugin from '@ckeditor/ckeditor5-text-align/src/alignment'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import PersonalizationFields from '@ckeditor/ckeditor5-personalization-fields/src/personalization-fields'


// Custom plugin
import LetterCase from '@ckeditor/ckeditor5-letter-case/src/letter-case'
import LetterSpacing from '@ckeditor/ckeditor5-letter-spacing/src/letter-spacing'
import FontColorPlugin from '@ckeditor/ckeditor5-font-color-picker/src/fontcolor'
import UnsubscribeLinks from '@/plugins/ckEditor/numberlyLinks/numberlyLinks'
import LineHeight from '@ckeditor/ckeditor5-line-height/src/lineheight'
import InsertNbsp from '@ckeditor/ckeditor5-nbsp/src/insertNbspPlugin'

// Configs
import colorsPallet from '@/assets/config/default-style/colors.conf'
import textDefaultStyle from '@/assets/config/default-style/text.conf'
import userConfig from '@/assets/config/user/user.conf'

// Services
import i18n from '@/plugins/i18n'
import { globalstates } from '@/services/states/states.service'

// Libraries
import {
  get as _get,
  orderBy as _orderBy
} from 'lodash-es'

const fonts = _orderBy([
  ...userConfig.fonts.map(f => f.name.includes('-') ? `${f.name.replaceAll('-', ' ')}, ${f.name}, arial` : `${f.name}, arial`),
  ...textDefaultStyle.fonts.imported.map(f => {
    const fallback = f.custom_fallback ? f.custom_fallback : 'arial'
    return f.name.includes('-') ? `${f.name.replaceAll('-', ' ')}, ${f.name}, ${fallback}` : `${f.name}, ${fallback}`
  }),
  ...textDefaultStyle.fonts.native
])

const range = Array.from(
  { length: 4 },
  (_, i) => Array.from(
    { length: 10 },
    (__, index) => parseFloat(((i + 1) + index * 0.1).toFixed(1))
  )
).flat();

const lineHeightOptions = [...range, 5.0];

// Conf@ckEditor
export default {
  loading: false,
  revertReadingDirection: false,
  editor: ClassicEditor,
  editorConfig: {
    language: {
      ui: i18n.locale,
      content: i18n.locale
    },
    plugins: [
      Heading,
      BoldPlugin,
      ItalicPlugin,
      UnderlinedPlugin,
      LinkPlugin,
      PersonalizationFields,
      FontSizePlugin,
      FontFamilyPlugin,
      FontColorPlugin,
      AlignmentPlugin,
      ListPlugin,
      LineHeight,
      LetterSpacing,
      LetterCase,
      Strikethrough,
      Superscript,
      InsertNbsp,
      UnsubscribeLinks,
      PasteFromOffice,
      EssentialsPlugin,
    ],
    fontFamily: {
      options: [
        'default',
        ...fonts
      ]
    },
    letterCase: {
      translation: {
        upperCase: i18n.t('t_upperCase_'),
        lowerCase: i18n.t('t_lowerCase_'),
        none: i18n.t('t_reset_'),
        tooltip: i18n.t('t_letter_case_')
      }
    },
    personalizationFields: {
      cb: eventEmit,
      translation: {
        tooltip: i18n.t('t_personalization_fields_tooltip_'),
      },
    },
    fontSize: {
      options: [
        'default',
        8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 28
      ]
    },
    fontColor: {
      exactColors: _get(globalstates, 'user').configLoaded && userConfig.colors.length
        ? colorsWithLabel(userConfig.colors)
        : colorsWithLabel(colorsPallet.default),
      columns: 8
    },
    letterSpacing: {
      translation: {
        default: i18n.t('t_default_'),
        tooltip: i18n.t('t_letter_spacing_')
      }
    },
    alignment: {
      options: [ 'left', 'center', 'right', 'justify' ]
    },
    InsertNbsp: {
      translation: {
        tooltip: i18n.t('t_nbsp_')
      }
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
      ],
    },

    lineHeight: { // specify your otions in the lineHeight config object. Default values are [ 0, 0.5, 1, 1.5, 2 ]
      options: lineHeightOptions,
      default: "1.4",
      translation: {
        tooltip: i18n.t('t_line_height_')
      }
    },

    toolbar: {
      items: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'link',
      'PersonalizationFields',
      'fontSize',
      '|',
      'fontFamily',
      '|',
      'FontColor',
      '|',
      'LetterCase',   
      '|', 
      'alignment',
      '|',
      'lineHeight',
      'bulletedList',
      '|',
      'numberedList',
      '|',
      'LetterSpacing',
      '|',
      'strikethrough',
      '|',
      'superscript',  
      '|',        
      'InsertNbsp',
      ],
      shouldNotGroupWhenFull: true,
    },
    link: {
      error: i18n.t('t_wrong-url-format_'),
      decorators: {
        addTargetToExternalLinks: {
          mode: 'automatic',
          callback: () => true,
          attributes: {
            target: '_blank'
          }
        }
      }
    }
  }
}
// Conf@ckEditor
